import React, {useState, useRef, useEffect } from 'react';

import { Container, Row, Col } from '../../components/Bootstrap/Grid';
import FormGroup from '../../components/Bootstrap/FormGroup';
import Button from '../../components/Bootstrap/Button';
import Layout from './parts/Layout';
import Comma from './parts/Comma';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const Login = ({ data }) => {
    const [status, setStatus] = useState(null);
    const form = useRef(null);

    useEffect(() => {
        form.current.addEventListener('loading', e => {
            setStatus('loading');
        });

        form.current.addEventListener('success', e => {
            setStatus('success');
        });
    
        form.current.addEventListener('error', e => {
            setStatus('error');
        });
    }, []);

    return (   
        <Layout layout="login">
            <Container pt={{xs: 6, lg: 10}} pb={{xs: 9, lg: 11}}>
                <Row {...className('h-100')}>
                    <Col col={{xs: 12, lg: 10}}>                
                        <div {...className(`${styles.login} px-2 px-sm-3 px-md-7 px-xl-8 px-xxl-10 py-6 py-lg-10 h-100 w-100 align-self-center`)}>
                            <div>
                                <h2 {...className('d3 text-primary mb-6')}>
                                    {data.title}
                                </h2>
                                <p {...className('mb-7')}>
                                    {data.text}
                                </p>
                                <form ref={form} id="data-protect-form">
                                    <FormGroup 
                                        {...className('button-2 border-0')} 
                                        type="email" 
                                        error={status === 'error'} 
                                        errorText={data.errorText} 
                                        errorClassName={styles.error} 
                                        placeholder={data.placeholder} 
                                        required={true}
                                        field="email"
                                        onFocus={() => {
                                            setStatus(null);
                                        }}
                                    />
                                    <Button 
                                        {...className(`${styles.button} button-2-b mt-lg-2 mt-xl-4`)} 
                                        disabled={status === 'loading'} 
                                        tag="button" 
                                        text={data.textButton} 
                                        kind="submit"
                                    />
                                </form>
                            </div>
                            <div {...className(`${styles.success} ${status === 'success' ? styles.show : ''} d-block text-white mt-2 mt-lg-2 mt-xl-4`)} >
                                {data.successText}
                            </div>
                            <Comma className={styles.comma} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Login;
