import React, { useEffect, useState } from 'react';

import LoginComponent from 'aliasLogin';

const mockData = {
  es: {
    title: 'Entra',
    text: 'Si estás leyendo esto, eres una parte muy importante de nuestra marca.',
    placeholder: 'Email',
    textButton: 'Enviar',
    errorText: 'Tu e-mail no es válido. Inténtalo de nuevo.',
    successText: 'Perfecto. Revisa tu e-mail.'
  },
  'pt-br': {
    title: 'Entre',
    text: 'Se você está lendo isso, você é uma parte muito importante da nossa marca.',
    placeholder: 'Email',
    textButton: 'Enviar',
    errorText: 'Seu email não é válido. Tenta de novo.',
    successText: 'Perfeito. Verifique seu email.'
  }
}

const Login = () => {
  const [ lang, setLang ] = useState('es');

  useEffect(() => {
    if (typeof window !== 'undefined' && window.sessionStorage.getItem('lang')) {
      setLang(window.sessionStorage.getItem('lang'));
    }
  }, []);

  return (<LoginComponent data={mockData[lang]} />)
}


export default Login;
