import React, { useRef, useEffect, useState, useCallback} from 'react';

import ReactQuill, { Quill as customQuill } from 'react-quill';
import { asField } from 'informed';
import 'react-quill/dist/quill.snow.css';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


const _output = data => data
    .replace(/<p> *<br> *<\/p>/ig, '')
    .replace(/<[ph][^>]*>/gi, '')
    .replace(/<\/[ph][^>]*>|<br>/gi, '<br/>')
    .replace(/(.*)(<br\/>)/gi, '$1')
    .replace(/^ *<br *\/>/gi, '');

let Inline = customQuill.import('blots/inline');

class LinkBlot extends Inline {
    static create(value) {
        let node = super.create(value);
        value = value.trim();
        if (!value.match(/^http|mailto|tel:|\/\//)) {
          value = 'http://' + value
        }
        node.setAttribute('href', value);
        node.setAttribute('target', '_blank');
        return node;
    }
    static formats(node) {
        return node.getAttribute('href');
    }
}

LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';

customQuill.register(LinkBlot);


const Quill = asField(({ fieldState, fieldApi, placeholder, defaultValue, ...props }) => {
 
    const quillRef = useRef(null); 

    const [isFocused, setFocused] = useState(false);

    const modules = {
        toolbar: [
            ['bold', 'italic', 'link']
        ]
    };
    const formats = ['bold', 'italic', 'link'];

    const { value, touched } = fieldState;
    const { setValue, setTouched } = fieldApi;

    const handleOnChange = useCallback((e) => {
        if (touched) {
            setValue(_output(e));
        }
    }, [touched]);

    const handleOnfocus = useCallback((e) => {
        setFocused(true);
        setTouched(e);
    }, []);

    const handleOnBlur = useCallback((e) => {
        setFocused(false);
    }, []);

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.editor.root.dataset.placeholder = placeholder;
        }
    }, [placeholder]);

    return (
        <ReactQuill theme="snow"
            ref={quillRef}
            {...className(`custom-quill  ${isFocused ? 'quill-focused':''} ${!value ? 'overflow-hidden':''}`)}
            formats={formats}
            placeholder={placeholder}
            defaultValue={defaultValue}
            modules={modules}
            onFocus={handleOnfocus}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
        />
    )
});

export default Quill;